import { type AUTHENTICATION_INTENT, type AUTHENTICATION_PROVIDERS, AUTHENTICATION_TYPE } from '~/constants/authentication'

export default function () {
  const authenticationModal = useAuthenticationModal()
  const authenticationIntent = useAuthenticationIntent()
  const { authenticationProvider } = useAuthentication()

  const useEnquiryOwnerName = () => useState<string | undefined>('enquiryOwnerName', () => undefined)

  function showSignup(params?: {
    intent?: AUTHENTICATION_INTENT
    ownerName?: string
    provider?: AUTHENTICATION_PROVIDERS // Only support email for now
  }) {
    const { intent, ownerName, provider } = params ?? {}

    authenticationProvider.value = provider

    authenticationIntent.value = intent

    const enquiryOwnerName = useEnquiryOwnerName()
    enquiryOwnerName.value = ownerName

    authenticationModal.value = AUTHENTICATION_TYPE.Signup
  }

  const isSignupVisible = computed(() => authenticationModal.value === AUTHENTICATION_TYPE.Signup)

  return {
    isSignupVisible,
    authenticationIntent,
    enquiryOwnerName: useEnquiryOwnerName(),
    showSignup,
  }
}
